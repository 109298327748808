<template>
  <div v-loading="isLoading" :class="paySucceed?'paySucceed':'login'">
    <div v-if="!paySucceed" class="login-content">
      <div class="login-title" style="font-size: 17px;margin: 59px auto;color: #000000;">支付订单</div>
      <div class="loginForm" style="">
        <div style="margin: 13px;">
          <div style="color: #000000;
            font-size: 24px;
            display: flex;justify-content: center;align-items: end;
            font-weight: 500;">¥<span style="font-size: 36px;margin-left: 2px;">{{ orderInfo.orderAmount || '--' }}</span></div>
          <div style="font-size: 12px;display: flex;justify-content: center;margin-top: 16px;color: #666666;">
            <div>{{ orderInfo.parkingLotName }}-长租</div>
          </div>
        </div>
        <div style="display: flex;
          justify-content: space-between;
          align-items: center; margin: 20px 13px;
          margin-bottom: 51px;color: #393B41;
          background-color: #FFFFFF;border-radius: 4px;
          padding: 12px;">
          <div style="font-size: 16px;font-weight: bold;">支付方式</div>
          <div v-if="checkWeixinClient" style="display: flex;justify-content: center;align-items: center;">
            <img src="../../assets/images/paytype_wechat.png" width="20px" height="20px">
            <div style="font-size: 14px;margin-left: 4px;">微信</div>
          </div>
          <div v-if="checkAliClient" style="display: flex;justify-content: center;align-items: center;">
            <img src="../../assets/images/paytype_ali.png" width="20px" height="20px">
            <div style="font-size: 14px;margin-left: 4px;">支付宝</div>
          </div>
        </div>
      </div>
      <div v-if="checkWeixinClient || checkAliClient" class="order" style="width:90%;margin: 43px 13px;">
        <!-- 显示商品列表等内容 -->

        <!-- 点击按钮后调起微信支付 :disabled="isPaying" -->
        <button v-if="orderInfo.orderAmount===0 && orderInfo.status==0" style="background: #3A69F5;border-radius: 6px; border: transparent;height: 50px;width: 100%;color: #ffffff;"
                @click="completion">完结订单</button>
        <button v-else-if="orderInfo.orderAmount===0 && orderInfo.status==1" style="background: #3A69F5;border-radius: 6px; border: transparent;height: 50px;width: 100%;color: #ffffff;"
                @click="closePage">已完结</button>
        <button v-else-if="paySucceed" style="background: #3A69F5;border-radius: 6px; border: transparent;height: 50px;width: 100%;color: #ffffff;">
          支付成功</button>
        <button v-else style="background: #3A69F5;border-radius: 6px; border: transparent;height: 50px;width: 100%;color: #ffffff;"
                @click="get_weixin_code_login" >
          去缴费</button>
      </div>
      <div v-else class="order" style="width:90%;margin: 13px 13px;">
        <div style="
          font-size: 15px;
          font-weight: 500;
          width: 100%;
          color: #FF323E;">
          提醒: 请使用微信或支付宝扫码支付。
        </div>
      </div>
      <!--      <WechatOrder></WechatOrder>-->
    </div>
    <div v-else>
      <div style="display: flex;justify-content: center;">
        <div class="loginForm" style="">
          <div style="margin: 13px;">
            <div style="color: #000000;
            font-size: 24px;
            display: flex;justify-content: center;align-items: end;
            font-weight: 500;">¥<span style="font-size: 36px;margin-left: 2px;">{{ orderInfo.orderAmount || '--' }}</span></div>
            <div style="font-size: 12px;display: flex;justify-content: center;margin-top: 16px;color: #666666;">
              <div>{{ orderInfo.parkingLotName }}-长租</div>
            </div>
          </div>
        </div>
        <div style="background-color: #3A69F5;width: 130px;height: 46px;font-size: 16px;
            color: white;display: flex;align-items: center;justify-content: center;border-radius: 6px;
            position: absolute;bottom: 44px;" @click="closePage">
          我知道了
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLeaseOrderDetail,
  getOpenId,
  accountPay,
  completion,
} from "@/api/system";
// import WechatOrder from "@/pages/WechatOrder.vue";
// import Numplate from "@/components/NumplateNoAdapt.vue";
// import wx from "_weixin-jsapi@1.1.0@weixin-jsapi";
import wx from "weixin-jsapi";


export default {
  name: "payment",
  data() {
    return {
      originUrl:'',
      leaseOrderId: '',
      source: 0,
      orderInfo:{
        plateNumber: '',
        orderAmount: 0,
      },
      paySucceed: false,
      isPaying: false,
      isLoading: false,
      aliAuthCode: '',
      aliOpenId: '',
    };
  },
  components:{
    // Numplate,
    // WechatOrder,
  },
  mounted() {
    // alert('mounted')
    this.leaseOrderId = this.$route.query.leaseOrderId;
    this.source = this.$route.query.source;
    if(this.originUrl===''){
      this.originUrl = window.location.href
    }
    // alert("原始url:"+this.originUrl)
    if (typeof WeixinJSBridge == 'undefined'){
      document.addEventListener('WeixinJSBridgeReady', function(){}, false);
    }else {
      // 注入支付宝jsapi
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
      document.body.appendChild(s);
      window.onload =()=>{   //初始化产生一个全局变量 AlipayJSBridge
        this.readys()
      }
    }
  },
  created() {
    var that = this
    if(this.$route.query.leaseOrderId && this.$route.query.leaseOrderId!=''){
      this.leaseOrderId = this.$route.query.leaseOrderId;
    }
    if(this.isWeixinClient()){
      var code = this.$route.query.code;
      // alert('created: '+JSON.stringify(this.$route.query))
      if(code && code!==''){
        console.log(code)
        getOpenId(code, 0).then(res =>{
          // alert('getOpenId: ', JSON.stringify(res), code)
          if(res && res.code===30 && res.returnObject.openId) {
            var openId = res.returnObject.openId
            getLeaseOrderDetail(this.leaseOrderId)
                .then(res => {
                      if (res && res.code === 30) {
                        // alert('getLeaseOrderDetail: '+ JSON.stringify(res))
                        console.log(res)
                        that.orderInfo = res.returnObject
                        var param = {
                          parkingOrderIntoId: that.$route.query.leaseOrderId,
                          accountBalance: that.orderInfo.accountBalance, // 账户余额
                          payAmount: that.orderInfo.orderAmount, // 支付金额
                          channel: 0, // 支付渠道 0 微信 1 支付宝
                          payType: 0, // 支付类型 0 支付 1 冲正
                          source: this.source, // 支付来源 0 app 1 pda 2 web
                          openId: openId, // 用户的openId
                          tenantType: that.orderInfo.tenantType,
                        }
                        // alert('param:'+JSON.stringify(param))
                        accountPay(param).then(res => {
                          if(res && res.code===30 && res.result){
                            // alert('accountPay res:'+JSON.stringify(res))
                            if(res.returnObject && res.returnObject.appId){
                              this.goToWechatPay(res.returnObject)
                            }else{
                              this.paySucceed = true;
                            }
                          }
                        })
                      }
                    }
                )
          }
        })
      }
      else{
        getLeaseOrderDetail(this.leaseOrderId)
            .then(res => {
                  if (res && res.code === 30) {
                    // alert('getLeaseOrderDetail: '+ JSON.stringify(res))
                    console.log(res)
                    this.orderInfo = res.returnObject
                  }
                }
            )
      }
    }else if(this.isAlipayClient()){
      // alert(this.leaseOrderId)
      getLeaseOrderDetail(this.leaseOrderId)
          .then(res => {
                if (res && res.code === 30) {
                  console.log(res)
                  this.orderInfo = res.returnObject
                  // alert('getLeaseOrderDetail: '+JSON.stringify(res))
                  // eslint-disable-next-line no-undef
                  AlipayJSBridge.call('getAuthCode', {
                    scopeNicks: ['auth_user'],
                    appId: that.orderInfo.zfbAppid,
                  }, function (res) {
                    // alert('getAuthCode: '+ JSON.stringify(res))
                    that.aliAuthCode = res.authCode || res.authcode; //获取authcode
                    getOpenId(that.aliAuthCode, 2).then(res =>{
                      // alert("getOpenId: "+that.aliAuthCode+' '+JSON.stringify(res))
                      if(res && res.code===30 && res.returnObject.openId) {
                        that.aliOpenId = res.returnObject.openId
                      }
                    })
                  })
                }
              }
          )
    }else{
      getLeaseOrderDetail(this.leaseOrderId)
          .then(res => {
                if (res && res.code === 30) {
                  console.log(res)
                  this.orderInfo = res.returnObject
                }
              }
          )
    }
  },
  computed:{
    checkWeixinClient() {
      return this.isWeixinClient();
    },
    checkAliClient() {
      return this.isAlipayClient();
    },
  },
  methods: {
    readys(callback){
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        // 如果没有注入则监听注入的事件
        document.addEventListener('AlipayJSBridgeReady', callback, false);
      }
    },
    isAlipayClient() {
      if (navigator.userAgent.indexOf('AlipayClient') > -1) {
        return true
      }
      return false
    },
    isWeixinClient() {
      var ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    // 完结订单
    completion(){
      completion(this.leaseOrderId, this.orderInfo.orderAmount).then(res => {
            console.log('checkToken', res)
            if (res || res.code === 30) {
              this.$message.success('订单完结成功')
              getLeaseOrderDetail(this.leaseOrderId)
                  .then(res => {
                    if (res && res.code === 30) {
                      console.log(res)
                      this.orderInfo = res.returnObject
                    }
                  });
            }
          }
      );
    },
    get_weixin_code_login() {
      var that = this
      if(this.isWeixinClient()){
        // http://testwechat.v6.idcfengye.com/wechat.html
        // http://localhost:7103/#/payment?leaseOrderId=1748682078007988227
        var uri = encodeURIComponent(this.delUrlParam(window.location.href,['code','state']));
        // url.searchParams.delete('code');
        // url.searchParams.delete('code');
        // url.searchParams.delete('state');
        // var uri = encodeURIComponent(this.originUrl);
        // uri = 'http://localhost:7103/#/payment'
        // uri = 'http://testwechat.v6.idcfengye.com/wechat.html'
        // debugger
        var appid = this.orderInfo.wechatAppid;//自己公众号的appid
        var url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri='+uri+'&response_type=code&scope=snsapi_userinfo&state=54321#wechat_redirect';
        // alert(url, window.location.href)
        window.location.href = url;
        // 这里走完就是已经授权了。如果授权了就会url中带有code
      }else if(this.isAlipayClient()){
        var param = {
          parkingOrderIntoId: that.$route.query.leaseOrderId,
          accountBalance: that.orderInfo.accountBalance, // 账户余额
          payAmount: that.orderInfo.orderAmount, // 支付金额
          channel: 1, // 支付渠道 0 微信 1 支付宝
          payType: 0, // 支付类型 0 支付 1 冲正
          source: this.source, // 支付来源 0 app 1 pda 2 web
          openId: that.aliOpenId, // 用户的openId
          tenantType: that.orderInfo.tenantType,
        }
        // alert(JSON.stringify(param))
        accountPay(param).then(res => {
          if(res && res.code===30 && res.result){
            // alert(JSON.stringify(res))
            if(res.returnObject && res.returnObject.tradeNo){
              this.goToAliPay(res.returnObject)
            }else{
              this.paySucceed = true;
            }
          }
        })

        // getOrderDetail(this.leaseOrderId)
        //     .then(res => {
        //           if (res && res.code === 30) {
        //             console.log(res)
        //             this.orderInfo = res.returnObject
        //             var param = {
        //               leaseOrderId: that.$route.query.leaseOrderId,
        //               accountBalance: that.orderInfo.accountBalance, // 账户余额
        //               payAmount: that.orderInfo.orderAmount, // 支付金额
        //               channel: 0, // 支付渠道 0 微信 1 支付宝
        //               payType: 0, // 支付类型 0 支付 1 冲正
        //               source: this.source, // 支付来源 0 app 1 pda 2 web
        //               openId: openId, // 用户的openId
        //             }
        //             // alert(JSON.stringify(param))
        //             accountPay(param).then(res => {
        //               if(res && res.code===30 && res.result){
        //                 if(res.returnObject && res.returnObject.appId){
        //                   this.goToWechatPay(res.returnObject)
        //                 }else{
        //                   this.paySucceed = true;
        //                 }
        //               }
        //             })
        //           }
        //         }
        //     )
      }
    },
    /**
     * 删除URL中指定search参数,会将参数值一起删除
     * @param {string} url 地址字符串
     * @param {array} param 要删除的参数key数组，如['name','age']
     * @return {string} 返回新URL字符串
     */
    delUrlParam(url, params) {
      for (var index = 0; index < params.length; index++) {
        var item = params[index];
        //必须加=号，避免参数值中包含item字符串
        var fromIndex = url.indexOf(item + "=");
        if (fromIndex !== -1) {
          // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
          var startIndex = url.indexOf("=", fromIndex);
          var endIndex = url.indexOf("&", fromIndex);
          var hashIndex = url.indexOf("#", fromIndex);

          var reg = "";
          if (endIndex !== -1) {
            // 后面还有search参数的情况
            var num = endIndex - startIndex;
            reg = new RegExp(item + "=.{" + num + "}");
            url = url.replace(reg, "");
          } else if (hashIndex !== -1) {
            // 有hash参数的情况
            num = hashIndex - startIndex - 1;
            reg = new RegExp("&?" + item + "=.{" + num + "}");
            url = url.replace(reg, "");
          } else {
            // search参数在最后或只有一个参数的情况
            reg = new RegExp("&?" + item + "=.+");
            url = url.replace(reg, "");
          }
        }
      }
      var noSearchParam = url.indexOf("=");
      if (noSearchParam === -1) {
        // 如果已经没有参数，删除？号
        url = url.replace(/\?/, "");
      }
      return url;
    },
    goToWechatPay(param) {
      // alert(JSON.stringify(param))
      var that = this
      const appId = param.appId; // 替换为真实的AppID
      const timeStamp = param.timeStamp; // 替换为真实的时间戳
      const nonceStr = param.nonceStr; // 替换为真实的随机字符串
      const packageValue =  `prepay_id=${param.prepayId}` // param.package; // 替换为真实的prepay_id或者package值
      const signType = 'RSA'; // 默认为 MD5，也可以选择其他加密算法
      const paySign = param.paySign; // 替换为真实的签名

      // 构建微信支付配置对象
      const configObj = {
        debug: false, // 调试模式开关，true表示打开，false表示关闭
        appId: appId,
        timestamp: timeStamp,
        nonceStr: nonceStr,
        signType: signType,
        package: packageValue,
        paySign: paySign,
        jsApiList: ['chooseWXPay', 'checkJsApi']
      };
      // alert('chooseWXPay configOjb:'+JSON.stringify(configObj))
      // 初始化微信支付SDK
      wx.config(configObj);
      wx.ready(() => {
        wx.chooseWXPay({
          appId: appId,
          timestamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: 'RSA', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: paySign, // 支付签名
          success: function(payRes) {
            console.log(payRes)
            // 支付成功后的回调函数
            that.$message.success('支付成功')
            debugger
            that.paySucceed = true;
          },
          cancel: function(payRes) {
            console.log(payRes)
            // alert('取消支付')
            that.$message.warning('取消支付')
            that.paySucceed = false;
            // window.location.href = that.originUrl;

            let url = new URL(window.location.href);
            // url.searchParams.delete('code');
            // url.searchParams.delete('state');
            window.history.replaceState(null, null, url);
          },
          fail: function(payRes) {
            console.log(payRes)
            // alert('支付失败')
            that.$message.error('支付失败')
            that.paySucceed = false;
            let url = new URL(window.location.href);
            // url.searchParams.delete('code');
            // url.searchParams.delete('state');
            window.history.replaceState(null, null, url);
          }
        })
      })
    },
    goToAliPay(param) {
      var that = this
      // eslint-disable-next-line no-undef
      AlipayJSBridge.call("tradePay", {
        tradeNO: param.tradeNo,
      }, function(result) {
        // that.code = result.resultCode;
        // alert('tradePay: '+ JSON.stringify(result))
        if (result.resultCode !== '9000') {
          that.$message.error('支付失败')
          that.paySucceed = false;
          // let url = new URL(window.location.href);
          // window.history.replaceState(null, null, url);
        } else {
          // 支付成功后的回调函数
          that.$message.success('支付成功')
          that.paySucceed = true;
        }
      });

      // // 初始化微信支付SDK
      // wx.config(configObj);
      // wx.ready(() => {
      //   wx.chooseWXPay({
      //     appId: appId,
      //     timestamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      //     nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
      //     package: packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      //     signType: 'RSA', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      //     paySign: paySign, // 支付签名
      //     success: function(payRes) {
      //       console.log(payRes)
      //       // 支付成功后的回调函数
      //       that.$message.success('支付成功')
      //       debugger
      //       that.paySucceed = true;
      //     },
      //     cancel: function(payRes) {
      //       console.log(payRes)
      //       // alert('取消支付')
      //       that.$message.warning('取消支付')
      //       that.paySucceed = false;
      //       // window.location.href = that.originUrl;
      //
      //       let url = new URL(window.location.href);
      //       // url.searchParams.delete('code');
      //       // url.searchParams.delete('state');
      //       window.history.replaceState(null, null, url);
      //     },
      //     fail: function(payRes) {
      //       console.log(payRes)
      //       // alert('支付失败')
      //       that.$message.error('支付失败')
      //       that.paySucceed = false;
      //       let url = new URL(window.location.href);
      //       // url.searchParams.delete('code');
      //       // url.searchParams.delete('state');
      //       window.history.replaceState(null, null, url);
      //     }
      //   })
      // })
    },
    savePlatform(platformCode) {
      window.localStorage.setItem("currentPlatform", platformCode);
    },

    //关闭页面
    closePage() {
      if(this.isWeixinClient()){
        wx.closeWindow()
      }else if(this.isAlipayClient()){
        // eslint-disable-next-line no-undef
        AlipayJSBridge.call('closeWebview');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.paySucceed{
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #F8F9FF;

  .header{
    background-image: url(../../assets/images/pay_success_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 45vw;
    color: white;
  }
}

.login {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  //background-color: #3A69F5;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url("../../assets/images/search_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;


  .login-content {
    width: 100vw;
    height: 100vh;
    //width: 1327px;
    //height: 592px;
    background: transparent;

    //backdrop-filter: blur(60px);

    //border-radius: 2px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loginForm {
      width: 90%;
      //height: 100vh;
      //width: 450px;
      //background: white;
      //background-image: url(../../assets/images/search_bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    }

    .sub-title {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #414344;
      margin-top: 70px;
      margin-left: 50px;
      margin-bottom: 10px;

    }

    .login-title {
      font-size: 17px;
      font-family: FZLanTingHei-M-GBK, FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 18px;
    }

    .login-form {
      font-size: 16px;
      font-weight: 400;
      color: #414344;
      line-height: 19px;
      margin-bottom: 10px;
      margin-left: 50px;
    }

    .login-button {
      height: 60px;
      width: 367px;
      background: #0768FD;
      border-radius: 4px;
      margin-left: 50px;

      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #FFFFFF;
      cursor: pointer;
    }

    .input-item {
      width: 367px;
      height: 60px;

      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #E3E1E1;
      opacity: 1;
      margin-left: 50px;
      margin-bottom: 10px;
      display: flex;
      //padding-left: 20px;
      //
      //display: flex;
      //flex-direction: row;
      //
      //align-items: center;
      //padding-right: 10px;

      img {
        width: 26px;
        height: 26px;
        margin: 0 10px;
      }

      input {
        border: none;
        outline: none;
        font-size: 16px;
        //width: 100%;
        padding: 5px 0;
        font-weight: 400;
        color: #414344;
        line-height: 19px;
        margin-left: 20px;
        margin-right: 20px;
        flex: 1;
      }
    }
  }
}
</style>
